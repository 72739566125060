interface Environment {
  apiUrl: string;
  basename?: string;
  type: EnvironmentType;
  maxUploadFileSizeInBytes: number;
  msal: {
    clientId: string;
    scopes: string[];
    redirectUri: string;
    authority: string;
  };
}

type EnvironmentType = "development" | "intelogy-test" | "abf-test" | "production";

const environment: Environment = {
  type:
    process.env.REACT_APP_STAGE === "development"
      ? "development"
      : process.env.REACT_APP_STAGE === "intelogy-test"
      ? "intelogy-test"
      : process.env.REACT_APP_STAGE === "abf-test"
      ? "abf-test"
      : "production",
  apiUrl: process.env.REACT_APP_API_URL ?? "",
  basename: process.env.REACT_APP_BASE_NAME ?? "",
  maxUploadFileSizeInBytes: Number.parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE_IN_BYTES ?? ""),
  msal: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_MSAL_AUTHORITY ?? "",
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? "",
    scopes: (process.env.REACT_APP_MSAL_SCOPES ?? "").split(","),
  },
};

if (environment.type !== "production") {
  console.log("Launching the app with environment", environment);
}

export default environment;
