import { PrimaryButton } from "@fluentui/react";
import PageHeader from "../../../common/components/PageHeader";
import { useTranslator } from "../../../common/state/translatorState";
import { useNavigate } from "react-router-dom";

const ContactReportWelcomeScreen = () => {
  const t = useTranslator();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader>{t.Menu.MenuItem.Home}</PageHeader>
      <p>
        Every time you meet a competitor (whether it is in a business or a social setting and regardless of what is discussed) you <b>MUST</b> complete a Contact Report setting out the date, location, attendees and a brief description of any discussions that took place. If your contact report contains any sensitive commercial information, please speak to a member of the legal team. This will allow us to monitor the level of contact personnel have with competitors and provides us with a written record of discussions, should allegations be made at a later date.
      </p>
      <p>
        Contact Reports should be submitted using the pro forma report, which can be accessed through the "Create a New Report” button below. Reports will be sent to the Compliance Team who will approve or reject each report submitted.
      </p>
      <p>
        Please ensure you provide adequate information within the report to allow the Compliance Team to review it. We need a brief description of all subjects discussed in order to be able to confirm that the meeting was compliant. It is often easiest to attach meeting minutes, should they exist. If no commercial discussions took place, please confirm this in the report.
      </p>
      <p>
        Please note that Contact Reports are not used as a way of obtaining legal approval for commercial negotiations/arrangements. Legal approval or sign off for a deal/contract/arrangement must be sought separately through direct contact with the ABF Legal Team.
      </p>
      <br/>
      <b>Adding attachment(s)</b>
      <ul>
        <li>If you wish to add an attachment to your Contact Report, simply create your report in the usual manner, then click on "browse" and select the document you wish to attach. Two documents can be attached in this way.</li>
        <li>Once you have completed all sections and attached relevant documentation, click on "Submit".</li>
      </ul>
      <div className="flex flex-row justify-center align-center">
        <PrimaryButton onClick={() => navigate("/contact-reports/new")}>{t.Menu.MenuItem.CreateContactReport}</PrimaryButton>
      </div>
    </>
  );
};

export default ContactReportWelcomeScreen;
